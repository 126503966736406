<template>
  <div class="header">
    <header class="container">
      <img src="@/assets/logo_header.png" alt="喵啰咿" title="喵啰咿" class="pull-left" @click="handleLogoClick">
      <ul class="navigation clearfix pull-right">
        <li :class="{active:curr_route_name=='index'}">
          <router-link to="/">首页</router-link>
        </li>
        <li :class="{active:(curr_route_name=='product'||curr_route_name=='detail')}">
          <router-link to="/product">产品中心</router-link>
        </li>
        <!-- <li :class="{active:curr_route_name=='h5game'}">
          <a :href="is_dev?'http://127.0.0.1:8001/h5':`https://m.${domain}.com/h5`">h5游戏</a>
        </li> -->
        <li>
          <a :href="is_dev?'http://127.0.0.1:8001/h5-gift':`https://m.${domain}.com/h5-gift`">礼包中心</a>
        </li>
        <li :class="{active:curr_route_name=='contact'}">
          <router-link to="/contact">联系我们</router-link>
        </li>
      </ul>
    </header>
    <div :class="$style.line"></div>
  </div>
</template>
<script>
export default {
  name: "mlyHeader",
  computed: {
    //当前路由名称
    curr_route_name() {
      return this.$route.name;
    },
    is_dev() {
      return process.env.NODE_ENV !== "production";
    },
    domain() {
      if (this.CONST.isDev) {
        return "127.0.0.1";
      }

      var __domain = document.domain;
      var __domain_array = __domain.split(".");
      if (__domain_array.length == 3) {
        __domain = __domain_array[1];
      } else if (__domain_array.length == 2) {
        __domain = __domain_array[0];
      }
      return __domain;
    }
  },
  methods: {
    handleLogoClick() {
      this.$router.push("/");
    }
  }
};
</script>

<style>
.header{
  position: relative;
}
header {
  min-width: 1200px;
  height: 88px;
  margin: 0 auto;
  overflow-x: hidden;
  position: relative;
}

header > img {
  position: absolute;
  top: 50%;
  margin-top: -26.5px;
}

header .navigation {
  padding-left: 0;
  margin-bottom: 0;
}

header .navigation li {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 0;
  position: relative;
  display: block;
  /*  */
  float: left;
  box-sizing: border-box;
}

header .navigation li a {
  position: relative;
  display: block;
  background-color: transparent;
  text-decoration: none;
  /*  */
  line-height: 68px;
  padding: 10px 40px;
  outline: none;
}

header .navigation li.active {
  background-image: url(~@/assets/nav_active.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

header .navigation li.active a {
  color: white;
}

header .navigation li a {
  color: black;
  font-size: 14px;
}
</style>
<style module>
.line {
  width: 100%;
  height: 1px;
  background-color: #dcdcdc;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
</style>
