import Vue from 'vue'
import Router from 'vue-router'

import index from '@/views/index/main';
import contact from '@/views/contact';
// import h5game from '@/views/h5game';
import product from '@/views/product/main';
import pageNotFound from '@/views/page-not-found';
import demo from '@/views/demo';

import '@/commons/device.js';


Vue.use(Router);

const isDev = process.env.NODE_ENV !== "production";

function getDomain() {
  var __domain = document.domain;
  var __domain_array = __domain.split(".");
  if (__domain_array.length == 3) {
    __domain = __domain_array[1];
  } else if (__domain_array.length == 2) {
    __domain = __domain_array[0];
  }
  return __domain;
}

/**
 * 每个页面的标题一样
 */
const router = new Router({
  mode: 'history',
  routes: [{
    path: '/',
    name: 'index',
    component: index,
    meta: {
      // title: '首页',
      toMobile: isDev ? 'http://127.0.0.1:8001/' : `https://m.${getDomain()}.com/` //当手机访问的时候，跳转到对应的手机端地址
    }
  }, {
    path: '/contact',
    name: 'contact',
    component: contact,
    meta: {
      // title: '联系我们'
    }
  }, {
    path: '/aboutus',
    name: 'aboutus',
    component: ()=>import('@/views/aboutus'),
    meta: {
      // title: '公司简介'
    }
  }, {
    path: '/product/:game_id',
    name: 'productById',
    component: product,
    meta: {
      // title: '产品中心'
    }
  }, {
    path: '/product',
    name: 'product',
    component: product,
    meta: {
      // title: '产品中心'
    }
  },{
    path:'/detail/:id',
    name:'detail',
    component:()=>import('@/views/detail/main'),
    props:true,
    meta:{
      // title:'游戏详情'
    }
  }, {
    path: '/demo',
    name: 'demo',
    component: demo,
    meta: {
      title: 'demo'
    }
  }, {
    path: '*',
    name: 'pageNotFound',
    component: pageNotFound
  }]
});

router.beforeEach((to, from, next) => {

  if (to.meta.toMobile) {
    if (device.mobile() || device.tablet()) {
      location.replace(to.meta.toMobile);
    }
  }
  next();
  // 路由切换完成之后，先销毁事件处理总线，再初始化事件处理总线，相当于把上一个路由的事件处理总线中所有的事件监听解绑
  window.EventBus = undefined;
  window.EventBus = new Vue();
});

export default router;
