<template>
  <div>
    <img src="@/assets/footer-line.png" alt="" class="footer-line">
    <footer class="container">
      <img src="@/assets/logo_footer.png" alt="">
      <div class="description">
        <router-link to="/aboutus">公司简介</router-link>
        <router-link to="/contact">联系我们</router-link>
        <ul>
          <li>健康游戏公告：抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当 适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活</li>
          <li>{{company}} 版权所有 Copyright&copy;2018
            <a href="./" style="margin-right:0">{{domain}}</a>.All rights Reserved.</li>
          <li>
            <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030602001732" target="_blank" v-if="domain==='254game'||domain==='127.0.0.1'">
              <img src="@/assets/ba_icon.png">粤公网安备 44030602001732号
            </a>
              <a href="http://www.miitbeian.gov.cn/" target="_blank" v-if="domain==='254game'||domain==='127.0.0.1'">
                粤ICP备17129008号
              </a>
              <a href="http://www.miitbeian.gov.cn/" target="_blank" v-else>
                沪ICP备18030015号
              </a>
            <a :href="wwwUrl" target="_blank">
                <img src="@/assets/wenhuajingying.png" class="whjy">
            </a>
          </li>
        </ul>
      </div>
    </footer>
  </div>
</template>
<script>
export default {
    name: "mlyFooter",
    computed: {
        //当前运行在哪个域名上
        domain() {
            if (this.CONST.isDev) {
                return "127.0.0.1";
            }

            var __domain = document.domain;
            var __domain_array = __domain.split(".");
            if (__domain_array.length == 3) {
                __domain = __domain_array[1];
            } else if (__domain_array.length == 2) {
                __domain = __domain_array[0];
            }
            return __domain;
        },
        //公司名称
        company() {
            if (this.domain === "mlygame" || this.domain === "mlyol") {
                return "上海喵啰咿科技有限公司";
            } else {
                return "深圳市拓建源科技发展有限公司";
            }
        },
        //文网文 url
        wwwUrl() {
            return this.domain === "254game" || this.domain === "127.0.0.1"
                //拓建源的文网文信息地址
                ? "http://sq.ccm.gov.cn:80/ccnt/sczr/service/business/emark/toDetail/1cdc7f8a6f824088b6cea45be2170d96"
                //喵啰咿的文网文信息地址
                : "http://sq.ccm.gov.cn:80/ccnt/sczr/service/business/emark/toDetail/76CA28728F633E49E053010A14AC3198";
        }
    }
};
</script>
<style>
.footer-line {
    user-select: none;
    display: block;
    max-width: 100%;
    min-width: 1200px;
    height: auto;
    margin: 50px 0;
}

footer {
    margin: 36px 0;
    /* margin-top: 152px; */
}

footer > img {
    margin: 0 113px;
}

footer > img,
footer .description {
    float: left;
}

footer .description > a,
footer .description > a:hover,
footer .description > a:visited {
    color: black;
}

footer a {
    margin-right: 35px;
    margin-bottom: 13px;
    outline: none;
    font-weight: bold;
    font-size: 14px;
}

footer ul {
    padding-left: 0;
}

footer ul a,
footer ul li {
    color: #666666;
    font-size: 12px;
    font-weight: normal;
}

footer ul li {
    list-style-type: none;
    margin: 13px 0;
}

footer .whjy {
    width: 30px;
    height: 30px;
}
</style>
